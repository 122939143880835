import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import RouterCards from 'components/Motion_Detection/Router_as_a_FTP_Server/RouterCards';
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/Router_as_a_FTP_Server/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Router or NAS as FTP Server",
  "path": "/Motion_Detection/Router_as_a_FTP_Server/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Use your Router / NAS as a FTP Server",
  "image": "./MD_SearchThumb_RouterAsFTPServer.png",
  "social": "/images/Search/MD_SearchThumb_RouterAsFTPServer.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-Router-FTP-Server_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Router or NAS as FTP Server' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use your Router / NAS as a FTP Server' image='/images/Search/MD_SearchThumb_RouterAsFTPServer.png' twitter='/images/Search/MD_SearchThumb_RouterAsFTPServer.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/Router_als_FTP_Server/' locationFR='/fr/Motion_Detection/Router_as_a_FTP_Server/' crumbLabel="FTP Server" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "router-or-nas-as-ftp-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#router-or-nas-as-ftp-server",
        "aria-label": "router or nas as ftp server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Router or NAS as FTP Server`}</h2>
    <p>{`Most modern routers now support USB mass storage devices (USB sticks, hard drives, etc. ...) to be plugged in - some even come with a large amount of on-board storage for you to use a Network-Attached-Storage (`}<strong parentName="p">{`NAS`}</strong>{`). This storage spaces can be written on by FTP clients like your INSTAR IP camera. All you need to do is to provide the storage and set up a FTP user inside your router and the camera can send snapshots - or in case of the HD cameras even whole video files - to the router. This can be vital if your camera or the included SD card (HD camera line) gets damaged and you want to figure out what happened. Of course you don´t need to upload those backup files to your local router - if you have a router in a different location, you can easily use it as an off-site storage backup system for your surveillance system! In this case you will have to use a DDNS address (like e.g. DynDNS or No-IP) inside the router for the camera to reach it.`}</p>
    <RouterCards mdxType="RouterCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      